<template>
  <div class="header-container">
    <div class="header-title-container">
      <div class="header-title-inner-container">
        <h1>Wohnen unterm Nussbaum</h1>
        <h3>Ihr neues Zuhause am Land</h3>
        <div class="header-navigation">
          <vimeo-player id="581158540" text="Gebäude ansehen"/>
          <a @click="goToApartments()">Wohnungen ansehen<v-icon>{{icons.right}}</v-icon></a>
        </div>
      </div>
    </div>
    <div class="header-container-filter"/>
  </div>
</template>

<script>
import { mdiChevronRight } from "@mdi/js";

const VimeoPlayer = () => import(/* webpackChunkName: "VimeoPlayer" */ '../VimeoPlayer');

export default {
  name: "Header",
  data: () => ({
    icons: {
      right: mdiChevronRight
    },
  }),
  components: {
    VimeoPlayer
  },
  methods: {
    goToApartments() {
      this.globalEvents.$emit('goToElement', '#apartments');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.header-container {
  background: url("../../../public/images/am-nussbaum-header.jpg") center center;
  background-size: cover;
  height: 700px;
  width: 100%;
  position: relative;
  @include MQ(XS) {
    background: url("../../../public/images/am-nussbaum-header.jpg") -400px center;
    height: 400px;
  }
  .header-container-filter {
    background: url('../../../public/images/pix.png');
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  .header-title-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    z-index: 2;
    @include MQ(XS) {
      bottom: 25px;
    }
    .header-title-inner-container {
      color: white;
      @include MQ(XS) {
        min-width: 320px;
      }
      @include MQ(S) {
        min-width: 650px;
      }
      @include MQ(M) {
        min-width: 800px;
      }
      @include MQ(L) {
        min-width: 800px;
      }
      h1 {
        font-size: 4em;
        @include MQ(XS) {
          font-size: 1.5em;
        }
        @include MQ(S) {
          font-size: 3.5em;
        }
      }
      h3 {
        font-size: 2em;
        @include MQ(XS) {
          font-size: 1em;
        }
      }
      .header-navigation {
        margin-top: 15px;
        padding: 10px 25px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        display: flex;
        justify-content: space-between;
        @include MQ(XS) {
          display: inline-block;
          text-align: center;
        }
        @include MQ(XS) {
          padding: 10px;
          justify-content: left;
          .vimeo-video-container {
            white-space: nowrap;
            width: auto;
          }
        }
        a {
          color: black;
          text-decoration: none;
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
